const navLinks = [
  {
    label: "Home",
    href:"/home#home"
  },
  {
    label: "About",
    href:"/home#about"
  },
  // {
  //   label: "Join",
  //   href:"/join#top"
  // },
];

export default navLinks;
